namespace RemoveUppercaseAccents {

    if (!("currentStyle" in Element.prototype)) {
        Object.defineProperty(Element.prototype, "currentStyle", {
            get: function () {
                return window.getComputedStyle(this);
            }
        });
    }

    function removeAccentedChars(text: any): any {
        return 'string' !== typeof text ?
            // handle cases that text is not a string
            text :
            // replace of uppercase accented characters
            text.
                replace(/\u0386/g, "\u0391"). // 'Ά':'Α'
                replace(/\u0388/g, "\u0395"). // 'Έ':'Ε'
                replace(/\u0389/g, "\u0397"). // 'Ή':'Η'
                replace(/\u038A/g, "\u0399"). // 'Ί':'Ι'
                replace(/\u038C/g, "\u039F"). // 'Ό':'Ο'
                replace(/\u038E/g, "\u03A5"). // 'Ύ':'Υ'
                replace(/\u038F/g, "\u03A9"). // 'Ώ':'Ω'
                replace(/\u0390/g, "\u03B9"). // 'ΐ':'ι'
                replace(/\u03AA/g, "\u0399"). // 'Ϊ':'Ι'
                replace(/\u03AB/g, "\u03A5"). // 'Ϋ':'Υ'
                replace(/\u03AC/g, "\u03B1"). // 'ά':'α'
                replace(/\u03AD/g, "\u03B5"). // 'έ':'ε'
                replace(/\u03AE/g, "\u03B7"). // 'ή':'η'
                replace(/\u03AF/g, "\u03B9"). // 'ί':'ι'
                replace(/\u03B0/g, "\u03C5"). // 'ΰ':'υ'
                replace(/\u03CA/g, "\u03B9"). // 'ϊ':'ι'
                replace(/\u03CB/g, "\u03C5"). // 'ϋ':'υ'
                replace(/\u03CC/g, "\u03BF"). // 'ό':'ο'
                replace(/\u03CD/g, "\u03C5"). // 'ύ':'υ'
                replace(/\u03CE/g, "\u03C9"); // 'ώ':'ω'
    };

    function normalizeUppercaseTransformedElement(element: HTMLElement): void {
        if ('uppercase' === getComputedStyle(element).textTransform) {
            if (element.innerHTML !== undefined) {
                element.innerHTML = removeAccentedChars(element.innerHTML);
            }
        }
    };

    function normalizeUppercaseTransformedElements(): void {
        let elements: any = document.getElementsByTagName('*');
        for (let element of elements) {
            normalizeUppercaseTransformedElement(element);
        }
    };

    // Process DOM Content

    if (/complete|interactive|loaded/.test(document.readyState)) {
        // In case the document has finished parsing, document's readyState will
        // be one of "complete", "interactive" or (non-standard) "loaded".
        normalizeUppercaseTransformedElements();
    } else {
        // The document is not ready yet, so wait for the DOMContentLoaded event
        document.addEventListener('DOMContentLoaded', normalizeUppercaseTransformedElements, false);
    }

    // Process DOM Content Updates

    // subscriber function
    function subscriber(mutations: MutationRecord[], _observer: MutationObserver) {
        for (let mutation of mutations) {
            if (!mutation.addedNodes.length) {
                continue;
            }
            mutation.addedNodes.forEach((node: Node) => {
                if (node instanceof HTMLElement) {
                    normalizeUppercaseTransformedElement(node);
                }
            });
        }
    }

    // instantiating observer
    const observer = new MutationObserver(subscriber);

    // observing target
    export function init(selectors: string): void {

        const target = document.querySelector(selectors);
        if (target != null) {

            // config object
            const config = {
                characterData: true,
                characterDataOldValue: true,
                childList: true,
                subtree: true
            };

            observer.observe(target, config);
        }
    }
}

RemoveUppercaseAccents.init('app');

export { }
